@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  src: url(../fonts/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/OpenSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/OpenSans-Bold.ttf) format('truetype');
}