$color-grey: #E3E7EA;
$color-grey1: #626B82;
$color-grey2: #F3F6F7;
$color-primary: #2E87CA;
$color-white: #ffffff;

.table-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

table {
  border-spacing: 0;
  border: 1px solid $color-grey;
  border-radius: 4px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  th {
    color: $color-grey1;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    padding: 0;
    &::before {
      content: "";
      position: absolute;
      right: 15px;
      top: 16px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    &.sort-asc {
      &::before {
        border-bottom: 5px solid #22543d;
      }
    }
    &.sort-desc {
      &::before {
        border-top: 5px solid #22543d;
      }
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
  th,
  td {
    margin: 0;
    border-bottom: 1px solid $color-grey;
    position: relative;
    &:last-child {
      border-right: 0;
    }
  }
  td {
    padding: 0.5rem;
    flex-grow: 1;
  }

  th {
    border-bottom: 2px solid #e0e4e5;
  }

  tbody {
    tr {
      &:hover {
        cursor: pointer;
        background: #e8e8e8;
      }
    }
  }

  .column-title {
    padding: 0.5rem;
    background: $color-grey2;
    border-bottom: 2px solid #e0e4e5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .column-filter {
    height: 27px;
    background: $color-white;
    input,
    fieldset {
      border: none !important;
    }
    .MuiInputBase-root {
      width: 100%;
      font-size: 12px;
    }
    .MuiSelect-select {
      height: auto;
      padding: 5px;
      color: $color-grey1;
    }
  }

  .column-status {
    &.scripted {
      background: $color-primary;
      color: #ffffff;
      border-radius: 4px;
      text-align: center;
    }
  }
}
