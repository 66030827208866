* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: 'OpenSans';
}

img {
  max-width: 100%;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}